import React, { useEffect, useState } from "react";
import { admin_talent_project as $$ } from "strings";
import $ from "strings/onboard";
import { Topbar } from "components/Topbar";
import Section from "components/Section";
import Nav from "pages/admin/Nav";
import Item from "./Item";
import useList from "hooks/useList";
import { fetchOwners } from "utils/fetch";
import { getAdminUser } from "utils/localStorageService";
import { usePageCache } from "contexts/pageCacheContext";
import NewTalentForm from "../NewTalentForm";
import UilPlusCircle from "@iconscout/react-unicons/icons/uil-plus-circle";

const grades = ["A+", "A", "A-", "B+", "B", "B-", "C", "Ungraded"];
const gradeOptions = grades.map((g, i) => ({
  label: g,
  value: g === 'Ungraded' ? 0 : 8 - i,
}));

// when searchText is present, it will be used to filter the results
// but value is still needed to for multi-select to work
const projectStatusOptions = [
  { value: 0, searchText: "projects_count>0", label: "On active projects" },
  { value: 1, searchText: "projects_count=0", label: "No active projects" },
  { value: 2, searchText: "candidate_status.has_completed_projects=1", label: "Has completed projects" },
];

const candidateStatusOptions = [
  { value: 0, searchText: "candidate_status.not_a_candidate=1", label: "Not a Candidate" },
  { value: 1, searchText: "candidate_status.never_been_a_candidate=1", label: "Never been a Candidate" },
  { value: 0, searchText: "candidate_status.new=1", label: $$.new_option },
  { value: 1, searchText: "candidate_status.maybe=1", label: $$.maybe_option },
  { value: 2, searchText: "candidate_status.hold=1", label: $$.hold_option },
  { value: 3, searchText: "candidate_status.short_list=1", label: $$.short_list_option },
  { value: 4, searchText: "candidate_status.scheduled=1", label: $$.scheduled_option },
  { value: 5, searchText: "candidate_status.submitted=1", label: $$.submitted_option },
  { value: 6, searchText: "candidate_status.intro_call=1", label: $$.intro_call_option },
];

const excludedStatusOptions = [
  { value: true, searchText: "excluded=1", label: 'Excluded' },
  { value: false, searchText: "excluded=0", label: 'Not Excluded' }
]

const fulltimeInterestOptions = [
  { value: true, searchText: "fulltime_interest=1", label: 'Interested in FTE' },
  { value: false, searchText: "fulltime_interest=0", label: 'Not Interested in FTE' }
]

const seniorityOptions = [
  { value: "Associate", label: "Associate" },
  { value: "Manager", label: "Manager" },
  { value: "Senior Manager", label: "Senior Manager" },
  { value: "Director", label: "Director" },
  { value: "VP", label: "VP" },
  { value: "CMO", label: "CMO" },
];

const specialtiesOptions = [
  { label: $.performance_marketing_option, value: 'performance_marketing' },
  { label: $.lifecycle_crm_option, value: 'lifecycle_crm' },
  { label: $.content_marketing_option, value: 'content_marketing' },
  { label: $.product_marketing_option, value: 'product_marketing' },
  { label: $.analytics_option, value: 'analytics' },
  { label: $.marketing_ops_option, value: 'marketing_ops' },
  { label: $.affiliate_option, value: 'affiliate' },
  { label: $.ecommerce_option, value: 'ecommerce' },
  { label: $.design_option, value: 'design' },
  { label: $.leadership_option, value: 'leadership' },
  { label: $.brand_marketing_option, value: 'brand_marketing' }
]

const employmentStatusOptions = [
  {label: $.w2_employee_option, value: 'w2_employee'},
  {label: $.freelancer_option, value: 'freelancer'},
  {label: $.student_option, value: 'student'},
  {label: $.searching_for_w2_option, value: 'searching_for_w2'},
  {label: $.open_to_fulltime_freelance_option, value: 'open_to_fulltime_freelance'}
];

const workingTimezonesOptions = [
  {label: 'Eastern', value: 'eastern'},
  {label: 'Central', value: 'central'},
  {label: 'Mountain', value: 'mountain'},
  {label: 'Pacific', value: 'pacific'}
];

const onsiteFrequencyOptions = [
  {label: $.regular_days_option, value: 'regular'},
  {label: $.occasional_meetings_option, value: 'occasional'},
  {label: $.special_events_option, value: 'special'},
  {label: 'Not Interested', value: 'not_interested' }
];

const travelDistanceOptions = [
  {label: $.local_travel_option, value: 'local'},
  {label: $.domestic_travel_option, value: 'domestic'},
  {label: $.international_travel_option, value: 'international'}
];

const rateFlexibilityOptions = [
  {label: 'Passion project', value: 'PassionProject'},
  {label: 'Longer contract', value: 'LongerContract'},
  {label: 'More hours per week', value: 'WeeklyWork'}
];

const businessHoursAvailableOptions = [
  {label: $.yes_option, value: 'yes'},
  {label: $.depends_on_day_option, value: 'depends_on_day'},
  {label: $.no_option, value: 'no'}
];

const talentTypeOptions = [
  { label: 'Select Type', value: null },
  { label: "Moonlighter", value: "Moonlighter" },
  { label: "Flexible Moonlighter", value: "FlexibleMoonlighter" },
  { label: "Freelancer", value: "Freelancer" },
  { label: "FTE Candidate Only", value: "FTECandidateOnly" }
];

const Page = ({ setAdminLogged }) => {
  const [modal, setModal] = useState();
  const { set } = usePageCache();
  const user = getAdminUser();

  useEffect(() => {
    set("url_talent", "/admin/talent");
  }, [set]);

  useEffect(() => {
    document.title = "Pipeline - Talent Search";
  }, []);

  const filters = {
    owners: {
      type: "select",
      title: "Owner",
      placeholder: "Type to Search",
      selectProps: { async: true, loadOptions: fetchOwners },
      data: { label: user.first_name + " " + user.last_name, value: user.id },
    },
    qualifications: {
      type: "qualification",
      title: "Qualification",
      placeholder: "Select a qualification",
      selectProps: {},
    },
    talent_grade: {
      type: "select",
      title: "Talent Grade",
      placeholder: "Select an option",
      selectProps: { options: gradeOptions },
      operator: "=",
      outputType: "number",
      isMulti: true,
      useValue: true
    },
    seniority: {
      type: "select",
      title: "Seniority",
      placeholder: "Select an option",
      selectProps: { options: seniorityOptions },
      isMulti: true,
    },
    rate: {
      min_index: "minimum_rate",
      max_index: "minimum_rate",
      type: "int_range",
      symbol: "$",
      title: "Pay Rate",
      placeholder: "per hour",
      freshKey: "minimum_rate_updated_at",
    },
    availability_hours: {
      type: "int",
      operator: ">=",
      title: "Availability Min",
      placeholder: "hours per week",
      freshKey: "availability_updated_at",
    },
    specialties_list: {
      type: "select",  
      selectProps: { options: specialtiesOptions },
      isMulti: true,
      useValue: true,
      title: "Specialties",
    },
    talent_type: {
      type: "select",
      selectProps: { options: talentTypeOptions },
      isMulti: true,
      useValue: true,
      title: "Talent Type",
    },
    project_status: {
      type: "select",
      title: "Project Status",
      placeholder: "Select an option",
      selectProps: { options: projectStatusOptions },
      isMulti: true,
      useValue: true,
    },
    candidate_status: {
      type: "select",
      title: "Candidate Status",
      placeholder: "Select an option",
      selectProps: { options: candidateStatusOptions },
      isMulti: true,
      useValue: true,
    },
    fulltime_interest: {
      type: "select",
      selectProps: { options: fulltimeInterestOptions },
      useValue: true,
      title: "FTE Interest",
    },
    onsite_frequency_list: {
      type: "select",
      selectProps: { options: onsiteFrequencyOptions },
      isMulti: true,
      useValue: true,
      title: "Onsite Frequency",
    },
    employment_status_list: {
      type: "select",
      selectProps: { options: employmentStatusOptions },
      isMulti: true,
      useValue: true,
      title: "Employment Status",
    },
    working_timezones_list:  {
      type: "select",
      selectProps: { options: workingTimezonesOptions },
      isMulti: true,
      useValue: true,
      title: "Working Timezones",
    },
    business_hours_available: {
      type: "select",
      selectProps: { options: businessHoursAvailableOptions },
      isMulti: true,
      useValue: true,
      title: "Business Hours Available",
    },
    travel_distance_preferences_list: {
      type: "select",
      selectProps: { options: travelDistanceOptions },
      isMulti: true,
      useValue: true,
      title: "Travel Distance",
    },
    rate_flexibility_list: {
      type: "select",
      selectProps: { options: rateFlexibilityOptions },
      isMulti: true,
      useValue: true,
      title: "Rate Flexibility",
    },
    years_of_experience: {
      type: "int",
      operator: ">=",
      title: "Years of Experience",
      placeholder: "years",
      freshKey: "availability_updated_at",
    },
    location: {
      type: "location",
      title: "Location",
      placeholder: "Filter by location",
    },
    excluded: {
      type: "select",
      selectProps: { options: excludedStatusOptions },
      isMulti: true,
      useValue: true,
      title: "Excluded Status",
    },
    created_at: {
      type: "date",
      title: "Created",
    },
    updated_at: {
      type: "date",
      title: "Updated",
    },
    background_check_passed: {
      type: "boolean",
      title: "Background Check Passed",
    },
  };

  const { Search, List, searchParams, listParams, Filters, filtersParams } =
    useList("admin_talent_list", "talent_index", Item, filters);

  return (
    <Section
      id="admin_talent_list"
      className="font-lato bg-white h-screen flex flex-col"
    >
      <Topbar>
        <Search {...searchParams} />
      </Topbar>
      <div className="flex-1 flex min-h-0">
        <Nav setAdminLogged={setAdminLogged} />
        <div className="flex-1 h-full overflow-hidden flex flex-col relative">
          <Filters {...filtersParams}>
            <div className="flex items-center mr-1">
              <IconBtn fullColored onClick={() => setModal("add_talent")}>
                <UilPlusCircle size="22" />
              </IconBtn>
            </div>
          </Filters>
          <List {...listParams} />
        </div>
      </div>
      {modal === "add_talent" && <NewTalentForm onClose={setModal} />}
    </Section>
  );
};

const IconBtn = ({ children, colored, fullColored, danger, ...props }) => (
  <button
    type={props.type || "button"}
    {...props}
    className="group focus:outline-none w-8 h-8"
  >
    <span
      className="group-focus:ring focus:outline-none w-8 h-8 overflow-hidden text-sm rounded inline-flex items-center justify-center text-white bg-link hover:bg-link-dark active:bg-link-darker"
      tabIndex="-1"
    >
      {children}
    </span>
  </button>
);

export default Page;
