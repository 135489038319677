import * as yup from 'yup';
import $ from "strings/onboard";
import { BasicDetailsIcon, ContactInformationIcon, NicheIcon } from "./icons/index";
import { PhoneNumberUtil } from 'google-libphonenumber';

const urlRegExp = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;

const phoneUtil = PhoneNumberUtil.getInstance();

export const validationMappings = {
  // contact
  0: yup.object().shape({
    firstName: yup
      .string()
      .required("First name required")
      .min(1, "Must be at least 1 character"),
    lastName: yup
      .string()
      .required("Last name required")
      .min(1, "Must be at least 1 character"),
    phone: yup
      .string()
      .required("Phone number required")
      .test('phone', "Must be a valid phone number", (phone) => {
        if (phone.length < 5) return false;
        return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
      },
    ),
    schedulingLink: yup
      .string()
      .matches(urlRegExp, "Enter a valid URL"),
    communicationPreferences: yup.
      string()
      .required("Select at least one option"),
  }),
  // employment
  1: yup.object().shape({
    employmentStatus: yup
      .string()
      .required("Select at least one option"),
    businessHoursAvailable: yup
      .string()
      .required("Select an option"),
    workingTimezones: yup
      .string()
      .required("Select at least one option"),
  }),
  // opportunity
  2: yup.object().shape({
    fulltimeInterest: yup
      .boolean()
      .required("Select an option"),
    onsiteFrequency: yup
      .string()
      .required("Select at least one option"),
    travelDistancePreferences: yup
      .string()
      .when(["onsiteFrequency"], {
        is: (onsiteFrequency) => 
          onsiteFrequency !== 'not_interested',
        then: schema => schema.required("Select at least one option"),
        otherwise: schema => schema.optional().nullable(true)
      })
  }),
  // availability
  3: yup.object().shape({
    hoursAvailable: yup
      .number()
      .required("Available hours required.")
      .min(0, "Hours cannot be less than zero.")
      .max(40, "Max hours reached! Please enter 40 hours or fewer per week."),
    hoursExpirationDate: yup
      .date()
      .required("Reminder date required"),
  }),
  // experience
  4: yup.object().shape({
    yearsOfExperience: yup
      .number()
      .required("Enter your years of experience.")
      .min(0, "Years of experience cannot be less than zero."),
    minimumRate: yup
      .number()
      .required("Enter your minimum hourly rate.")
      .min(0, "Your rate cannot be less than zero.")
  }),
  // specialties
  5: yup.object().shape({
    specialties: yup
      .string()
      .min(1, "Select at least one option")
      .required("Select at least one option"),
  }),
  // skills
  6: yup.object().shape({
    qualificationsRatings: yup
      .object()
      .required( "Select at least one skill.")
      .test('test-name', "Select at least one skill.", function (val) {
        return Object.values(val).filter(v => v > 0).length > 0
      })
    }),
  // tools
  7: yup.object().shape({
    toolsRatings: yup
      .object()
      .required( "Select at least one tool.")
      .test('test-name', "Select at least one tool.", function (val) {
        return Object.values(val).filter(v => v > 0).length > 0
      })
    }),
  // industries
  8: yup.object().shape({ 
    industries: yup
      .object()
      .required( "Select at least one industry.")
      .test('test-name', "Select at least one industry.", function (val) {
        return Object.keys(val).length > 0
      })
  }),
  // other
  9: yup.object().shape({ 
    additionalNotes: yup
      .string()
  })
}


export const navigationMappings = {
  "contact": {
    icon: <ContactInformationIcon />,
    activeIcon: <ContactInformationIcon active={true} />,
    title: $.contact_information_title,
    steps: [0],
    requiredFields: ['firstName', 'lastName', 'phone', 'communicationPreferences'],
    validatedFields: ['firstName', 'lastName', 'phone', 'schedulingLink', 'communicationPreferences']
  },
  "details": {
    icon: <BasicDetailsIcon />,
    activeIcon: <BasicDetailsIcon active={true} />,
    title: $.basic_details_title,
    steps: [1, 2, 3, 4],
    requiredFields: [
      'employmentStatus',
      'businessHoursAvailable',
      'workingTimezones',
      'fulltimeInterest',
      'onsiteFrequency',
      'hoursAvailable',
      'hoursExpirationDate',
      'yearsOfExperience',
      'minimumRate'
    ],
    validatedFields: [
      'employmentStatus',
      'businessHoursAvailable',
      'workingTimezones',
      'fulltimeInterest',
      'hoursAvailable',
      'hoursExpirationDate',
      'yearsOfExperience',
      'minimumRate'
    ],
    screens: {
      "employment": {
        title: $.employment_status_title,
        step: 1,
        requiredFields: ['employmentStatus', 'businessHoursAvailable', 'workingTimezones']
      },
      "opportunity": {
        title: $.opportunity_preferences_title,
        step: 2,
        requiredFields: ['fulltimeInterest', 'onsiteFrequency']
      },
      "availability": {
        title: $.availability_title,
        step: 3,
        requiredFields: ['hoursAvailable', 'hoursExpirationDate']
      },
      "experience": {
        title: $.experience_title,
        step: 4,
        requiredFields: ['minimumRate']
      }
    }
  },
  "niche": {
    icon: <NicheIcon />,
    activeIcon: <NicheIcon active={true} />,
    title: $.your_niche_title,
    steps: [5, 6, 7, 8, 9],
    requiredFields: [
      'specialties',
      'qualificationsRatings',
      'toolsRatings',
      'industries'
    ],
    validatedFields: [
      'specialties',
      'qualificationsRatings',
      'toolsRatings',
      'industries',
    ],
    screens: {
      "specialties": {
        title: $.specialties_title,
        step: 5
      },
      "skills": {
        title: $.skills_title,
        step: 6
      },
      "tools": {
        title: $.tools_title,
        step: 7
      },
      "industries": {
        title: $.industries_title,
        step: 8
      },
      "other": {
        title: $.other_experience_title,
        step: 9
      }
    }
  }
}