import $ from "strings/onboard";


export const SPECIALTY_OPTIONS = [
  {
    label: $.performance_marketing_option,
    value: 'performance_marketing'
  },
  {
    label: $.lifecycle_crm_option,
    value: 'lifecycle_crm'
  },
  {
    label: $.content_marketing_option,
    value: 'content_marketing'
  },
  {
    label: $.product_marketing_option,
    value: 'product_marketing'
  },
  {
    label: $.analytics_option,
    value: 'analytics'
  },
  {
    label: $.marketing_ops_option,
    value: 'marketing_ops'
  },
  {
    label: $.affiliate_option,
    value: 'affiliate'
  },
  {
    label: $.ecommerce_option,
    value: 'ecommerce'
  },
  {
    label: $.design_option,
    value: 'design'
  },
  {
    label: $.leadership_option,
    value: 'leadership'
  },
  {
    label: $.brand_marketing_option,
    value: 'brand_marketing'
  }
]

export const INDUSTRIES = [
  {
    "id": "043a4d37-a38a-4e86-b021-00d4d669a165",
    "name": "Agency"
  },
  {
    "id": "83c3d897-511e-4850-ac99-b4a7d03135a3",
    "name": "B2B - Enterprise"
  },
  {
    "id": "738b4134-420d-4cf8-9473-14bdcc29b749",
    "name": "B2B - SMB"
  },
  {
    "id": "2aaf2fea-4a4a-49b7-bd45-4fa44c905308",
    "name": "B2C"
  },
  {
    "id": "ba8194d8-7312-46cd-a567-7cf3a6ceaa5a",
    "name": "Beauty"
  },
  {
    "id": "d08c3be2-ed0b-4889-9a9b-14aa260580bf",
    "name": "Crypto"
  },
  {
    "id": "0837a088-12bd-43bf-9f0b-fc248cb3ea17",
    "name": "D2C eCommerce"
  },
  {
    "id": "dd7b5db7-df97-45a5-85a3-d431b8eec98f",
    "name": "Education"
  },
  {
    "id": "917133bd-d3bc-4551-9fb3-6ba2cdccd53e",
    "name": "Entertainment"
  },
  {
    "id": "41e17503-4b26-4202-9854-e6c9c6cee0ee",
    "name": "Fashion"
  },
  {
    "id": "a4b14d95-950a-4191-b8bd-c642f3fd2d4b",
    "name": "Fintech"
  },
  {
    "id": "99da40f0-8b68-4bdd-b9d1-9b7a4f91cdd7",
    "name": "Food and Beverage"
  },
  {
    "id": "6906109a-2588-47a6-8a32-9edd9f3e76d3",
    "name": "Gaming"
  },
  {
    "id": "13e0b9b3-92be-443f-bdb4-0b2fdcfc3fe0",
    "name": "Hardware"
  },
  {
    "id": "c0df5fa5-c378-4ea4-9ef0-0f6dd93f1b67",
    "name": "Health/Wellness"
  },
  {
    "id": "a4c47009-ca21-4550-ae4a-9c8cde560c86",
    "name": "Healthcare"
  },
  {
    "id": "87806a78-de71-447c-932b-c7042999ede4",
    "name": "Hospitality"
  },
  {
    "id": "16a2bdf0-d18d-4ea6-96f4-42e80ad07523",
    "name": "Marketplace"
  },
  {
    "id": "7cc3efd4-a04f-4c9e-aa6f-c51a286cb18e",
    "name": "Mobile App"
  },
  {
    "id": "99fa4a04-a7af-4394-9f48-7ec3e319aa99",
    "name": "Retail"
  },
  {
    "id": "dd763fed-dda8-4d9c-bcd6-12b56bd7f546",
    "name": "SaaS"
  },
  {
    "id": "18be3e87-2606-4f43-bd09-2f80e9271d3a",
    "name": "Shopping"
  },
  {
    "id": "0912ca98-856f-4e0c-b7f5-ff1bb58a5777",
    "name": "Subscription"
  },
  {
    "id": "d1fcf85f-88b5-4d38-9139-fdf9dda460df",
    "name": "Travel"
  },
  {
    "id": "114bb193-055e-41ca-a8ea-b38e24d40258",
    "name": "Web3"
  }
];
