import React, { useState, useMemo, useEffect } from "react";
import clsx from 'clsx';
import { Link, useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import { admin_talent_header as $ } from "strings";
import useCachedFetch from "hooks/useCachedFetch";
import {
  getTalentAgreements,
  getTalentProjects,
  patchAccountV2,
  patchUser,
  patchTalentProject,
  postTalentProject,
  postTalentNote
} from "utils/adminApi";
import { toFormat, moveDays } from "utils/date";
import { getAdminUser } from "utils/localStorageService";
import { formatPhone, withHttp } from "utils/str";
import AlertError from "components/AlertError";
import Select from "components/CompactSelect";
import DatePicker from "components/ListDatePicker";
import EditableField from "components/EditableField";
import EditableDoubleField from "components/EditableDoubleField";
import Header from "components/ListHeader";
import Input from "components/ListEditable";
import Indicator from "components/ListIndicator";
import AddressInput from "components/AddressInput";
import PhoneInput from "components/PhoneEditable";
import AvailabilityLastUpdate from "../../AvailabilityLastUpdate";
import userImg from "assets/user.svg";
import UilPhone from "@iconscout/react-unicons/icons/uil-phone";
import UilClock from "@iconscout/react-unicons/icons/uil-clock";
import UilPin from "@iconscout/react-unicons/icons/uil-map-pin";
import UilBill from "@iconscout/react-unicons/icons/uil-bill";
import UilUser from "@iconscout/react-unicons/icons/uil-user-square";
import UilUserCircle from "@iconscout/react-unicons/icons/uil-user-circle";
import UilUserCheck from "@iconscout/react-unicons/icons/uil-user-check";
import UilFolderPlus from "@iconscout/react-unicons/icons/uil-folder-plus";
import UilLink from "@iconscout/react-unicons/icons/uil-link";
import UilLinkedIn from "@iconscout/react-unicons/icons/uil-linkedin";
import UilCalendar from "@iconscout/react-unicons/icons/uil-calender";
import AlertSuccess from "../../../../../components/AlertSuccess";
import TalentTypeToast from "./TalentTypeToast";

const pronounsOptions = [
  { label: $.blank_option, value: null },
  { label: $.she_her_option, value: "she/her" },
  { label: $.he_him_option, value: "he/him" },
  { label: $.they_them_option, value: "they/them" },
  { label: $.other_option, value: "other" },
];

const seniorityOptions = [
  { label: $.not_set_option, value: null },
  { label: "Associate",value: "Associate",  },
  { value: "Manager", label: "Manager" },
  { value: "Senior Manager", label: "Senior Manager" },
  { value: "Director", label: "Director" },
  { value: "VP", label: "VP" },
  { value: "CMO", label: "CMO" }
];

const talentTypeOptions = [
  { label: 'Select Type', value: null },
  { label: "Moonlighter", value: "Moonlighter" },
  { label: "Flexible Moonlighter", value: "FlexibleMoonlighter" },
  { label: "Freelancer", value: "Freelancer" },
  { label: "FTE Candidate Only", value: "FTECandidateOnly" }
];

const grades = ["A+", "A", "A-", "B+", "B", "B-", "C"];
const ungradedOption = [{ label: 'Ungraded', value: null }];
const gradeOptions = ungradedOption.concat(grades.map((g, i) => ({
  label: g,
  value: 8 - i,
})));

const yesNoOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" }
];

const TalentHeader = ({
    data,
    setData,
    talentOwners
 }) => {
  const { id } = useParams();
  const alert = useAlert();
  const [hasClip, setHasClip] = useState(false);
  const [errors, setErrors] = useState({});
  const [states, setStates] = useState({});
  const [currentRole, setCurrentRole] = useState({currentTitle: '', currentCompany: ''});
  const projects = useCachedFetch("admin_talent_projects", getTalentProjects, id, { page_size: 9999 });
  const adminUser = getAdminUser();

  const agreements = useCachedFetch(
    "admin_talent_agreements",
    getTalentAgreements,
    id
  );

  const emailToDisplay = useMemo(() => {
    const tmpEmailSuffix = 'tmp.rightsideup';
    if (!data) {
      return '';
    } else if (!data.primary_talent_email) {
      return '';
    } else if (data.primary_talent_email.includes(tmpEmailSuffix)) {
      return '';
    } else {
      return data.primary_talent_email;
    }
  }, [data]);

  const activeProject = useMemo(() => {
    if (projects.data && Array.isArray(projects.data.results)) {
      return projects.data.results.find(p => p.active === true);
    };
  }, [projects.data]);

  useEffect(() => {
    if (data.current_title === null || !data.current_title) {
      setCurrentRole({currentTitle: '', currentCompany: ''});
    } else {
      setCurrentRole({currentTitle: data.current_title.split(' at ')[0], currentCompany: data.current_title.split(' at ')[1]})
    }
  }, [data.current_title]);

  const copyEmailToClipboard = () => {
    if (!emailToDisplay) return;
    navigator.clipboard.writeText(emailToDisplay);

    setHasClip(true);
    setTimeout(() => {
      setHasClip(false);
    }, 1000);
  }

  const onChange = async (key, value, dataValue) => {
    const origVal = data[key];
    setData({ ...data, [key]: dataValue || value });
    setStates({ ...states, [key]: "saving" });

    const call = async () => {
      try {
        await patchAccountV2(id, { [key]: value });
        setStates({ ...states, [key]: "saved" });
      } catch (e) {
        setStates({ ...states, [key]: "error" });
        setData({ ...data, [key]: origVal });
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };
    await call();
  };

  const onSaveAvailability = async (availability) => {
    const origVal = data["availability_capacity"];

    const now = new Date().toISOString().slice(0, -1);
    const expDate = moveDays(now, 60);
    setStates({ ...states, availability_capacity: "saving" });
    setData({ ...data,
      availability_updated_at: now,
      availability_expiration_date: expDate,
      availability_updater: `${adminUser.first_name} ${adminUser.last_name}`,
      availability_capacity: availability
    });

    const call = async () => {
      try {
        await patchAccountV2(id, {
          availability_capacity: availability,
          availability_expiration_date: expDate
        });
        setStates({ ...states, availability_capacity: "saved" });
      } catch (e) {
        setStates({ ...states, availability_capacity: "error" });
        setData({ ...data, availability_capacity: origVal });
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };
    await call();
  }

  const onSaveEmail = async (email) => {
    if (!email) return;
    const origVal = emailToDisplay;
    setData({ ...data, primary_talent_email: email });
    setStates({ ...states, email: "saving" });

    const call = async () => {
      try {
        await patchUser(id, { primary_talent_email: email });
        setStates({ ...states, email: "saved" });
      } catch (e) {
        setStates({ ...states, email: "error" });
        setData({ ...data, primary_talent_email: origVal });
        const duplicateId = e.response?.data?.users?.[0]?.id;
        if (duplicateId) {
          const pageUrl = `/admin/contacts/${duplicateId}/contact_info`;
          alert.info(
            <AlertSuccess>
              <div>
                A record with this email already exists. <a href={pageUrl} className="underline">Click here to view it.</a>
              </div>
            </AlertSuccess>);
        } else {
          alert.error(<AlertError error={e} onRetry={call}/>);
        }
      }
    };
    await call();
  };

  const onSaveName = async (first_name, last_name) => {
    const origVals = { first_name: data.first_name, last_name: data.last_name };
    setData({ ...data, first_name, last_name });
    setStates({ ...states, name: "saving" });

    const call = async () => {
      try {
        await patchAccountV2(id, { first_name, last_name });
        setStates({ ...states, name: "saved" });
      } catch (e) {
        setStates({ ...states, name: "error" });
        setData({ ...data, primary_talent_email: origVals });
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };
    await call();
  };

  const onSaveGrade = async (grade) => {
    let addExcludedNote = false;
    const origVal = data.talent_grade;
    const toSend = {
      talent_grade: grade
    }
    if (grade === 2 && data.excluded === false) {
      toSend.excluded = true;
      toSend.excluded_reason = "skills_experience_misalignment"
      addExcludedNote = true;
    }
    setData({ ...data, ...toSend });
    setStates({ ...states, talent_grade: "saving" });

    const call = async () => {
      try {
        await patchAccountV2(id, toSend);
        if (addExcludedNote) {
          await postTalentNote({
            body: 'Excluded Reason: Skills or Experience Misalignment; Talent Grade C',
            talent: id,
            note_tag: 'excluded',
            created_by_id: adminUser.id,
            created_by: adminUser.first_name + " " + adminUser.last_name,
          });
        }
        setStates({ ...states, talent_grade: "saved" });
      } catch (e) {
        setStates({ ...states, talent_grade: "error" });
        setData({ ...data, talent_grade: origVal });
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };
    await call();
  }

  const updateRole = async (name, company, origVal) => {
    const call = async () => {
      try {
        await patchTalentProject(activeProject?.id, { name, company });
        setStates({ ...states, current_title: "saved" });
      } catch (e) {
        setStates({ ...states, current_title: "error" });
        setData({ ...data, current_title: `${origVal.currentTitle} at ${origVal.currentCompany}`});
        setCurrentRole(origVal);
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };

    await call();
  }

  const createRole = async (name, company, origVal) => {
    if (!name || !company) return;
    const call = async () => {
      try {
        await postTalentProject({
          name,
          company,
          user: id,
          active: true,
          start_date: toFormat(new Date(), "yyyy-MM-dd"),
          description: name,
        });
        setStates({ ...states, current_title: "saved" });
      } catch (e) {
        setStates({ ...states, current_title: "error" });
        setData({ ...data, current_title: `${origVal.currentTitle} at ${origVal.currentCompany}`});
        setCurrentRole(origVal);
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };

    await call();
  }

  const onSaveRole = (name, company) => {
    const origVal = {...currentRole};
    setData({ ...data, current_title: `${name} at ${company}`});
    setCurrentRole({ currentTitle: name, currentCompany: company });
    setStates({ ...states, current_title: "saving" });

    activeProject ? updateRole(name, company, origVal) : createRole(name, company, origVal);
  }

  const onSaveLocation = async (location) => {
    const {
      full_address,
      location_id,
      city,
      state,
      state_code,
      country,
      zipcode,
      timezone_id,
      timezone_name
    } = location;

    const origVal = data.full_address;
    setData({ ...data, full_address });
    setStates({ ...states, full_address: "saving" });

    const call = async () => {
      try {
        await patchAccountV2(id, {
          full_address,
          location_id,
          city,
          state,
          state_code,
          country,
          zipcode,
          timezone_id,
          timezone_name
        });
        setStates({ ...states, full_address: "saved" });
      } catch (e) {
        setStates({ ...states, full_address: "error" });
        setData({ ...data, full_address: origVal });
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };
    await call();
  };

  const onClickTalentType = () => {
    alert.success(<TalentTypeToast />, {timeout: 10000});
  }

  return (
    <div id="admin_talent_header" className="bg-white h-full w-full flex p-2">
      <div className="flex flex-col mr-1">
        <div className="flex-1">
          <img
            src={data.profile_photo || userImg}
            className={clsx(
              "rounded w-16 h-16 ",
              data.excluded && "border-4 border-red",
              (!data.excluded && data.availability_utilization >= 40) && "border-4 border-orange-darker",
              ((!data.excluded && data.availability_utilization < 40) && data.projects_count > 0) && "border-4 border-yellow-dark",
            )}
            alt={data.fullname}
          />
          {data.excluded &&
            <div className="-mt-1 py-1 w-16 text-center bg-red text-white text-xs font-lato rounded">
              {$.excluded_label}
            </div>
          }
          {(!data.excluded && data.availability_utilization >= 40) &&
            <div className="-mt-1 py-1 w-16 text-center bg-orange-darker text-white text-xs font-lato rounded">
              {data.availability_utilization === 40 ? $.fully_utilized_label : $.overutilized_label}
            </div>
          }
          {((!data.excluded && data.availability_utilization < 40) && data.projects_count > 0) &&
            <div className="-mt-1 py-1 w-16 text-center bg-yellow-dark text-white text-xs font-lato rounded">
              {$.on_active_projects_label}
            </div>
          }
        </div>
        <div className="flex-1"></div>
      </div>
      <div className="flex flex-col w-1/3">
        <div className="flex flex-1 flex-col">
          <div className="flex w-full items-start gap-1">
            <EditableDoubleField
              first={data.first_name}
              last={data.last_name}
              firstPlaceholder={$.first_name_placeholder}
              lastPlaceholder={$.last_name_placeholder}
              firstRequired={true}
              lastRequired={true}
              onChange={(first, last) => {
                onSaveName(first, last);
              }}
              inputClass="w-28"
              indicator="name"
            />
            <Select
              className="w-28"
              value={
                data.pronouns
                  ? pronounsOptions.find((o) => o.value === data.pronouns)
                  : { label: $.blank_option, value: null }
              }
              options={pronounsOptions}
              onChange={(v) => onChange("pronouns", v?.value || null)}
            />
          </div>
          <div className="flex w-full items-center">
            <div className="flex w-full items-center">
              {currentRole &&
                <EditableDoubleField
                  className="w-full"
                  first={currentRole?.currentTitle}
                  last={currentRole?.currentCompany}
                  firstPlaceholder={$.title_placeholder}
                  lastPlaceholder={$.company_placeholder}
                  firstRequired={true}
                  lastRequired={false}
                  divider={"at"}
                  onChange={(first, last) => {
                    onSaveRole(first, last);
                  }}
                  inputClass="w-48"
                  indicator="role"
                />
              }
            </div>
          </div>
          <div className="flex w-full items-center">
            <div className="flex items-center">
              <EditableField
                value={emailToDisplay}
                displayValue={emailToDisplay ? emailToDisplay : $.add_email_address}
                copiable={true}
                onCopyClick={copyEmailToClipboard}
                onSave={(v) => {
                  onSaveEmail(v)
                }}
              />
              {hasClip &&
                <span className="text-kasmir text-xs font-normal ml-1 italic">
                  {$.copied_clipboard}
                </span>
              }
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col">
         <Header Icon={UilLink} text={$.attached_links_title} />
          <div className="flex items-center gap-1">
            <UilLinkedIn size="14"/>
            {data.linkedin_url ?
              <Link
                className="text-sm font-bold text-midnight"
                to={{pathname: withHttp(data.linkedin_url) }}
                target="_blank"
              >
                {data.linkedin_url}
              </Link> :
              <Link
                className="text-sm font-bold text-kasmir underline"
                to={`/admin/talent/${id}/attachments`}
              >
                {$.add_linked_in_link}
              </Link>
            }
          </div>
          {data.calendar_link ?
            <div className="flex items-center gap-1">
              <UilCalendar size="14"/>
               <Link
                className="text-sm font-bold text-midnight"
                to={{pathname: withHttp(data.calendar_link) }}
                target="_blank"
              >
                {data.calendar_link}
              </Link>
            </div> :
            data.personal_website ?
              <div className="flex items-center gap-1">
                <UilLink size="14"/>
                <Link
                  className="text-sm font-bold text-midnight"
                  to={{pathname: withHttp(data.personal_website) }}
                  target="_blank"
                >
                  {data.personal_website}
                </Link>
            </div> : <></>
          }
        </div>
      </div>
      <div className="w-1/3 flex flex-col items-center justify-start gap-2">
        <div className="flex w-full flex-col">
          <Header Icon={UilPin} text={$.location_title}>
            <Indicator state={states.full_address} />
          </Header>
          <div className="flex align-center justify-start">
            <AddressInput
              defaultFullAddress={data.full_address}
              onSave={(v) =>  onSaveLocation(v)}
              canDelete={false}
            />
          </div>
        </div>
        <div className="flex w-full flex-col">
          <Header Icon={UilPhone} text={$.phone_number_title}>
            <Indicator state={states.phone_number} />
          </Header>
          <div className="flex items-center gap-1">
            <PhoneInput
              type="tel"
              name="phone_number"
              text={formatPhone(data.phone_number)}
              defaultValue={formatPhone(data.phone_number)}
              onChange={(v) => onChange("phone_number", v || null)}
            />
            <div className="flex items-center gap-1 h-5">
              <span className="ml-2 text-xs text-right flex-1">
                {$.sms_optin_label}:{" "}
                {agreements?.data?.textmessage ? "Yes" : "No"}
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div className="flex w-full">
            <div className="flex flex-col mr-4">
              <Header Icon={UilClock} text={$.availability_uc_title}>
                <Indicator state={states.availability_capacity} />
              </Header>
              <div className="group relative flex items-center gap-1">
                <div className="flex items-center gap-1 h-5">
                  <span className="text-sm text-kasmir">{data.availability_utilization || 0}</span> /
                </div>
                <Input
                  type="number"
                  text={
                    data.availability_capacity !== null
                      ? `${data.availability_capacity} hours per week`
                      : $.not_set_option
                  }
                  defaultValue={data.availability_capacity}
                  onChange={(v) => {
                    if (v <= 40 && v >= 0) {
                      setErrors((e) => ({ ...e, availability: null }));
                      onSaveAvailability(v || null)
                    } else {
                      setStates({ ...states, availability: "error" });
                      setErrors((e) => ({
                        ...e,
                        availability: v < 0 ? $.availability_negint_error : $.availability_max_error,
                      }));
                    }
                  }}
                />
                <div className="flex flex-col bottom-full w-content bg-cove text-white rounded px-1 py-1 text-center absolute z-30 font-bold text-xs hidden group-hover:inline">
                  <div className="ml-2 text-xs">{data.availability_capacity - data.availability_utilization} available hours</div>
                  {data.availability_updater &&
                    data.availability_updated_at ? (
                      <div>
                        <AvailabilityLastUpdate
                          updatedBy={data.availability_updater}
                          updatedAt={data.availability_updated_at}
                          format={'daysText'}
                        />
                      </div>
                    ) : (
                      <></>
                    )
                  }
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <Header Icon={UilCalendar} text='Valid Until'>
                <Indicator state={states.availability_expiration_date} />
              </Header>
              <div className="flex gap-1">
                <DatePicker
                  wrapperClassName="date-picker-w-full"
                  placeholderText="Choose date"
                  value={
                    data && data.availability_expiration_date
                    ? new Date(data.availability_expiration_date)
                    : null
                  }
                  format="MMMM dd, yyyy"
                  monthly={false}
                  onChange={(v) => {
                    onChange(
                      "availability_expiration_date",
                      toFormat(v, "yyyy-MM-dd'T'hh:mm")
                    )
                    setErrors((e) => ({ ...e, availability: null }));
                    }}
                  />
              </div>
            </div>
          </div>
          {errors.availability && (
            <div className="flex items-center text-xs text-red">
              {errors.availability}
            </div>
          )}
        </div>
      </div>
      <div className="w-1/6 flex flex-col items-center justify-start gap-2">
        <div className="flex w-full flex-col">
          <Header Icon={UilFolderPlus} text='Type'>
            <Indicator state={states.talent_type} />
          </Header>
          <div className="flex items-center gap-1" onClick={onClickTalentType}>
            <Select
              className="-ml-1 w-full"
              value={
                data.talent_type
                  ? talentTypeOptions.find((t) => t.value === data.talent_type)
                  : { label: 'Select Type', value: null }
              }
              onChange={(v) => onChange("talent_type", v?.value || null)}
              options={talentTypeOptions}
              isDisabled
            />
          </div>
        </div>
        <div className="flex w-full flex-col">
          <Header Icon={UilFolderPlus} text={$.seniority_title}>
            <Indicator state={states.seniority} />
          </Header>
          <Select
            className={"-ml-1 w-full"}
            value={
              Array.isArray(data.seniority) && data.seniority[0]
                ? seniorityOptions.find(
                    (o) =>  (o.value)?.toLowerCase() === (data.seniority[0])?.toLowerCase()
                  )
                : typeof data.seniority === "string" &&
                  data.seniority.split("|")?.[0]
                ? seniorityOptions.find(
                    (o) => (o.value)?.toLowerCase() === (data.seniority.split("|")?.[0])?.toLowerCase()
                  )
                : [{ label: $.not_set_option, value: null }]
            }
            onChange={(v) => onChange("seniority", v?.value || null)}
            options={seniorityOptions}
          />
        </div>
        <div className="flex w-full flex-col">
          <Header Icon={UilBill} text={$.pay_rate_title}>
            <Indicator state={states.minimum_rate || states.ideal_rate} />
          </Header>
          <div className="flex items-center h-5">
            <Input
              type="number"
              text={
                Number.isInteger(Number(data.minimum_rate))
                  ? "$" + data.minimum_rate
                  : $.none_set
              }
              defaultValue={data.minimum_rate}
              onChange={(v) => {
                if (
                  !Number.isInteger(
                    Number(data.ideal_rate)
                  ) ||
                  v <= data.ideal_rate
                ) {
                  setErrors((e) => ({ ...e, rate: null }));
                  onChange("minimum_rate", parseInt(v));
                } else {
                  setStates({ ...states, rate: "error" });
                  setErrors((e) => ({
                    ...e,
                    rate: $.rate_max_error,
                  }));
                }
              }}
            />
            <span className="mx-1">-</span>
            <Input
              type="number"
              text={
                Number.isInteger(Number(data.ideal_rate))
                  ? "$" + data.ideal_rate
                  : $.none_set
              }
              defaultValue={data.ideal_rate}
              onChange={(v) => {
                if (
                  !Number.isInteger(Number(data.minimum_rate)) ||
                  v >= data.minimum_rate
                ) {
                  setErrors((e) => ({ ...e, minimum_rate: null }));
                  onChange("ideal_rate", parseInt(v));
                } else {
                  setStates({ ...states, rate: "error" });
                  setErrors((e) => ({
                    ...e,
                    rate: $.rate_min_error,
                  }));
                }
              }}
            />
          </div>
          {errors.rate && (
            <div className="flex items-center text-xs text-red">
              {errors.rate}
            </div>
          )}
        </div>
      </div>
      <div className="w-1/6 flex flex-col items-center justify-start gap-2">
        <div className="flex w-full flex-col">
          <Header Icon={UilUserCheck} text={$.talent_grade_title}>
            <Indicator state={states.talent_grade} />
          </Header>
          <Select
            className="-ml-1 w-28"
            value={
              data.talent_grade
                ? {
                    label: grades[8 - data.talent_grade],
                    value: data.talent_grade,
                  }
                : ungradedOption
            }
            onChange={(v) => onSaveGrade(v?.value || null)}
            options={gradeOptions}
          />
        </div>
        <div className="flex w-full flex-col">
          <Header Icon={UilUser} text={$.talent_owner_title} />
          <div className="cursor-pointer">
            {talentOwners?.length ?
              <Link
                className="text-sm font-bold text-midnight"
                to={`/admin/talent/${id}/roles`}
              >
                {talentOwners}
              </Link> :
              <Link
                className="text-sm font-bold text-kasmir underline"
                to={`/admin/talent/${id}/roles`}
              >
                {$.add_talent_owner_link}
              </Link>
            }
          </div>
        </div>
        <div className="flex w-full flex-col">
          <Header Icon={UilUserCircle} text={$.onboarded_title}>
            <Indicator state={states.onboarded} />
          </Header>
          <Select
            className="-ml-1 w-8"
            value={data.onboarded
              ? yesNoOptions.find((o) => o.value === data.onboarded)
              : { label: "No", value: false }
            }
            onChange={(v) => onChange("onboarded", v.value || false)}
            options={yesNoOptions}
          />
        </div>
      </div>
    </div>
  );
};

export default TalentHeader;
